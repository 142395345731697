.warning{
    position: "absolute";
    width: 80vw;
    height: 100svh;
    background-color: rgba(0, 0, 0);
    
    text-align: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    z-index: 1000;
    display: none;
}

@media (max-width: 700px) {
  .main{
      display:grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      width: 100svw;
      height: 100svh;
      margin: 0;
      background-color:rgb(235, 229, 224)
  }

  .tagline {
  font-family: "Inter", sans-serif;
  font-size: .6em;
  grid-column: 1;
  grid-row: 1;
  align-self: end; 
  justify-self: end; 
  color: rgb(138, 138, 138); 
  padding-right: 1em;
  z-index: 1000;
}

/* MENU */
.initials{
    text-align: left;
    grid-row: header;
    grid-column:body-right/body-end;
    grid-row: header-start/header-end;
    align-self: center;
    justify-self: left;
}

#initials{
    font-size: 2.5em;
    font-family: "Slackside One", cursive;
    text-decoration: none;
    line-height: 10px;
    justify-self: center;
}

  .content{
    overflow: hidden;
    display: grid;
    padding-top: -50px;
    background-position-x: center;
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    border-radius:0px;
    overflow: hidden;
    margin: 0;
    background-color: rgb(235, 229, 224);
    grid-template-rows: [header-start]70px[header-end] 1fr 1fr 1fr 1fr 1fr 1fr 1fr [footer-start] 70px [footer-end];
    grid-template-columns: [body-start] 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr [body-right] 1fr [body-end];
    min-height: 0; 
    min-width: 0;
    anchor-name: --content;
  } 

/*HOME*/
#name{
    grid-row: 5/6;
    grid-column: body-start/body-end;
    animation: fade-in .5s;
}

h1{
    color: rgb(45, 45, 45);
    text-align: center;
    font-size: 3em;
    font-family: "Ibarra Real Nova", serif;
    line-height: 30px;
}

#creative{
    font-family: "Slackside One", cursive;
    color: crimson;
    font-size: 1em;
    line-height: 8px;
    text-decoration: none;
    z-index: 2;
}

/*ABOUT*/
#about-text{
    grid-row: 6/8;
    grid-column: 2/9;
    z-index: 1;
    padding-top: 0px;
    align-self:center;
    justify-self: center;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    animation: fade-in 0.5s ease-in forwards;
}

#about-initials{
    grid-row: 1/span 5;
    grid-column: 2/5;
    z-index: 1;
    font-size: 2em;
    font-family: "Slackside One", cursive;
    text-decoration: none;
    color:crimson;
    animation: fade-in 0.5s ease-in forwards;
} 

#about-image{
    grid-row: 2/6;
    grid-column: body-start/body-end;
    width: 220px;
    align-self:center;
    justify-self: center;
    z-index: 2;
    overflow: hidden;
    animation: fade-in 0.5s ease-in forwards;

    
}




#warning-text{
  font-size: 3em;
  padding: 10%;
  color: rgb(255, 65, 71);
}

/*RESUME*/
.PDF{
    grid-column: 1/-1;
    grid-row: 2/-2;
    border-radius: 20px;
    width: 90%;
    justify-self: center;
}

#prev-project{
    display: none;
}

#next-project{
    display: none;
}

#escape{
    display:none;
}
#download{
    display: none;
}

/*PROJECT*/
.project-image-container{
    grid-column:2/-2 ;
    grid-row: 2/5;
    justify-self: left;
    align-self:last baseline;
}

.project-image{
    object-fit: contain;
    width:100%; 
    height:100%;
    max-height: 30vh;
}

.project-body{
    grid-column:2/-2 ;
    grid-row: 5/footer-end;
    align-content: start;
    transition: all .6s ease-in-out;
    color: black;
    justify-content: center;
    text-align: center;
    user-select: none;
}

.project-body:hover {
    color: inherit;
    text-shadow: inherit;
    background-color:rgba(0, 0, 0, 0)  
}

#project-title{
    font-size: 1.6em;
    line-height:normal;
}

#project-role{
    font-size: 1em;
    line-height:normal;
    font-style: italic;
}

#project-billing{
    font-size: 1em;
    line-height:normal;
    font-weight: 100;
}

.project-text{
    text-align: left;
    margin:0;
    color: inherit;
    padding-left: 0px;
}
}