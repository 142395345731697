
:root {
  --background:rgb(230, 225, 222);

  --main: rgb(45, 45, 45);
  --third: rgb(82, 82, 82);
  --second: crimson;

  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 100;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: "Inter var", sans-serif;
  }
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: "Inter var", sans-serif;
  }
}

@keyframes fade-in {
    
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

html, body {
  background-color: --third;
  overflow-x: hidden;
  scroll-behavior: smooth;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

/*FONT STANDARDS*/

h1{
    color: rgb(45, 45, 45);
    text-align: center;
    font-size: 4.8em;
    font-family: "Ibarra Real Nova", serif;
    line-height: 52px;
}

h2{
    color: rgb(45, 45, 45);
    text-align: center;
    font-size: 1em;
    font-family: "Ibarra Real Nova", serif;
    line-height: 52px;
}


a{
    font-weight:700;
    font-size: 1.2em;
    text-decoration: none;
}

p{
    font-size: 1em;
    font-family: "Ibarra Real Nova", serif;
    margin: 0;
    line-height: 15px;
    color: rgb(45, 45, 45);
    font-weight: 400;
}


