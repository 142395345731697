
.main{
  display:grid;
  grid-template-columns: 1fr 1fr [left-left] 50px [left-right] 600px [right-left] 50px [right-right] 1fr 1fr;
   grid-template-rows: 1fr 1fr  [top-top]40px [top-bottom] 400px  [bottom-top]40px [bottom-bottom]1fr 1fr;
  width: 100vw;
  height: 100vh;
  margin: 0;
}



.tagline {
    font-family: "Inter", sans-serif;
  font-size: .6em;
  grid-column: 5 / -1; 
  grid-row: 3 / -1;
  align-self: end; 
  justify-self: end; 
  color: rgb(138, 138, 138); 
  padding-right: 1em;
}

.content{
    overflow: hidden;
    display: grid;
    padding-top: -50px;
    background-position-x: center;
    grid-row: 4/span 4;
    grid-column: 4/span 5;
    width: 600px;
    height: 400px;
    border-radius:8px;
    overflow: hidden;
    margin: 0;
    background-color: rgb(235, 229, 224);
    grid-template-rows: [header-start]70px[header-end] 1fr 1fr 1fr 1fr 1fr 1fr 1fr [footer-start] 70px [footer-end];
    grid-template-columns: [body-start] 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr [body-right] 1fr [body-end];
    min-height: 0; 
    min-width: 0;
}

/* MENU */
.initials{
    align-content: center;
    text-align: center;
    grid-row: header;
    grid-column:body-right/body-end;
    grid-row: header-start/header-end;
    align-items: center;
    justify-items: center;
    
}

#initials{
    font-size: 2.5em;
    font-family: "Slackside One", cursive;
    text-decoration: none;
    line-height: 10px;
    justify-self: center;
}

.menu{
    background-color: transparent;
    grid-row: footer-start/footer-end;
    align-content: center;
    text-align: center;
}

.menu-button{
    color: rgb(82, 82, 82);
    font-family: "Ibarra Real Nova", serif;
    font-weight: 100;
    grid-row: footer-start/footer-end;
}

.menu-button:hover{
    cursor: pointer;
    color: black;
}

.inactive {
  color: var(--menu);
}

.active {
    color: rgb(82, 82, 82);
  color:crimson;
}

.active:hover{
    color:crimson;
    cursor: default;
}

#about{
    grid-column: 2;
}

#contact{
    grid-column: 4;
}

#project{
    grid-column: 6;
}
#resume{
    grid-column: 8;
}




/*HOME*/
#name{
    grid-row: 5/6;
    grid-column: body-start/body-end;
    animation: fade-in .5s;
}

#creative{
    font-family: "Slackside One", cursive;
    color: crimson;
    font-size: 1em;
    line-height: 8px;
    text-decoration: none;
    z-index: 2;
}

/*ABOUT*/
#about-text{
    grid-row: header-end/footer-start;
    grid-column: 2/6;
    z-index: 1;
    animation: fade-in 0.5s ease-in forwards;
}

#about-initials{
    grid-row: 1/span 5;
    grid-column: 2/5;
    z-index: 1;
    font-size: 1.5em;
    font-family: "Slackside One", cursive;
    text-decoration: none;
    color:crimson;
    animation: fade-in 0.5s ease-in forwards;
} 

#about-image{
    grid-row: 4/span 7;
    grid-column: 6/ span 8;
    width: 175px;
    z-index: 2;
    overflow: hidden;
    opacity: 80% !important;
    animation: fade-in 0.5s ease-in forwards;
}

/*CONTACT*/
.contact-text{
    color: black;
    font-family: "Ibarra Real Nova", serif;
    text-align: center;
    animation: fade-in 1s;
    grid-column: body-start/body-end;
    grid-row: 5;
    align-self: center;
    line-height: 1.8em;
}
.contact-images{
    width:1em;
    opacity: .8;
    padding: 6px;
}

.contact-images:hover{
    opacity: 1;
}

#contact-email:hover{
    color: crimson;
}

#contact-phone:hover{
    color: crimson;
}

#contact-email{
    color: black;
    font-weight: 200 ;
}

#contact-phone{
    color: black;
    font-weight: 200 ;
}

/*RECENT*/
.project-list{
    font-size: 1em;
    grid-row: header-end/ footer-start;
    align-content: center;
    grid-column: 2/ span 8;
    margin: 0;
    animation: fade-in 1s;
}

.project-titles{
    color: rgb(45, 45, 45);
    text-align: left;
    font-size: 1.6em;
    font-family: "Ibarra Real Nova", serif;
    line-height: 38px;
    font-weight: 100;
}

.project-titles:hover{
    color:crimson;
    cursor: pointer;
}

/*PROJECT-MENU*/
.project-button{
    opacity: 0;
    font-size: 3.5em;
    line-height: 0px;
    color: rgb(82, 82, 82);;
    font-weight: 100;  
    font-family: "Slackside One", cursive;
    align-self: center;
    justify-self: center;
    animation-delay: .8s;
    -webkit-animation-delay: .8s;
    animation-name: fade-in;
    -webkit-animation-name: fade-in;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    animation-fill-mode: forwards ;
    cursor: pointer;
    user-select: none;
}

.project-button:hover{
    color: black;
}

#prev-project{
    grid-row: top-bottom/bottom-top; 
    grid-column: left-left/left-right;
}

#next-project{
    grid-row: top-bottom/bottom-top; 
    grid-column: right-left/right-right;

}

#escape{
    grid-column:left-right/right-left;
    grid-row: bottom-top/bottom-bottom;
    align-self: center;
    font-size: 1.7em;
    padding-bottom: .5em ;
    text-align: center;
    margin-left: 5%;
}

#download{
    grid-column:left-right/right-left;
    grid-row: bottom-top/bottom-bottom;
    justify-self: right;
    align-self: center;
    font-size: 1.7em;
    padding-bottom: .5em ;
    text-align: center;
    margin-right: 5%;
}

/*PROJECT*/
.project-image-container{
    grid-column:1/span 9 ;
    grid-row: 1/span 9;
    height: 400;
    width: 600;
}

.project-image{
    object-fit: cover;
    width:100%; 
    height:100%;
}

.project-body{
    grid-column: 1/body-end;
    grid-row: header-start/footer-end;
    align-content: center;
    transition: all .6s ease-in-out;
    color: transparent;
    user-select: none;
}

.project-body:hover {
    color: rgb(230, 225, 222);
    text-shadow: 2px 2px 10px #000000;
    background-color:rgba(0, 0, 0, 0.5)  
}

#project-title{
    font-size: 1.6em;
    line-height:normal;
}

#project-role{
    font-size: 1em;
    line-height:normal;
    font-style: italic;
}

#project-billing{
    font-size: 1em;
    line-height:normal;
    font-weight: 100;
}

.project-text{
    text-align: left;
    margin:0;
    color: inherit;
    padding-left: 65px;
}

/*RESUME*/
.PDF{
    grid-column: 1/-1;
    grid-row: 1/-1;
    height: 100%;
    width: 100%;
    align-self: center;
    justify-self: center;
    overflow: scroll;
    box-sizing: border-box;
    border: 5px solid black;
}

.pdf-image{
    align-self: first baseline;
    grid-column: 1/-1;
    grid-row: 2/-3;
    height: 100%;
    width: 100%;
    object-fit: contain;

}

.download-button{
    grid-column: 1/-1;
    grid-row: -3;
    object-fit: contain;
    background-color: transparent;
    border: none;
    margin-top: 5px;
    align-self: start;
    justify-self: center;
    color:rgb(82, 82, 82)
}

.download-button:hover{
    color: black;
    background-color: transparent;
    cursor: pointer;
}

.download-button:target{
    color: black;
    background-color: transparent;
}




.pdf-button:hover{
    color: rgb(209, 209, 209);
}

.project-background{
    background-color: black;
    grid-column:1/span 9 ;
    grid-row: 1/span 9;
}

.fade-image {
  transition: opacity 1000ms ease-in-out; /* Match the duration */
    grid-column:1/span 9 ;
    grid-row: 1/span 9;
    height: 400;
    width: 600;
    width:100%; 
    height:100%;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}